import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

function Certificaciton() {
  const {
    allFile: { nodes },
  } = useStaticQuery(graphql`
    query QueryCertificacition {
      allFile(filter: { relativeDirectory: { eq: "certificaciones" } }) {
        nodes {
          publicURL
          id
          name
          childImageSharp {
            sizes {
              srcWebp
            }
          }
        }
      }
    }
  `);
  return (
    <div className="wrapper white certificaciton">
      {nodes.map((row, i) => {
        return (
          <div key={i} className="continer-image">
            <img
              src={row.childImageSharp.sizes.srcWebp}
              alt={row.name}
              className="wow animate__animated animate__bounce animate__slideInDown"
            />
          </div>
        );
      })}
    </div>
  );
}

export default Certificaciton;
