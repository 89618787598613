import React from 'react';

const Map = () => {
  return (
    <iframe
      title="Ecolodge Naturlog Playa Guio"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3985.7980873870174!2d-72.71596342416353!3d2.572309097405865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e179da74435e621%3A0xbf8112081afc2d52!2sEcolodge%20Naturlog%20Playa%20Guio!5e0!3m2!1ses-419!2sco!4v1681761116811!5m2!1ses-419!2sco"
      width="100%"
      height="450"
      lowfullscreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    />
  );
};

export default Map;
