import React from 'react';
import Helmet from 'react-helmet';
import Logo from '../assets/images/logo.svg';
import { url, defaultDescription, social, defaultTitle, socialLinks } from '../config/config';

export const SEO = ({ title = defaultTitle, decription = defaultDescription, location = '' }) => {
  return (
    <Helmet>
      <meta charset="UTF-8" />
      <title>{title}</title>
      <link
        rel="shortcut icon"
        type="image/x-icon"
        href="/static/logo-90f29cab542e2499f33ae0fc3b2a7add.svg"
      />
      <meta http-equiv="content-type" content="text/html; charset=utf-8" />
      <meta name="description" content={decription} />
      <meta name="image" content={Logo} />

      <meta property="og:url" content={`${url}${location}/?ref=naturlogplayaguio.com`} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={decription} />
      <meta property="og:image" content={Logo} />
      <meta property="fb:app_id" content={social.facebook} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={socialLinks.twitter} />
      <meta name="twitter:site" content={social.twitter} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={decription} />
      <meta name="twitter:image:src" content={Logo} />

      <meta name="instagram:card" content="summary" />
      <meta name="instagram:creator" content={socialLinks.instagram} />
      <meta name="instagram:site" content={social.instagram} />
      <meta name="instagram:title" content={title} />
      <meta name="instagram:description" content={decription} />
      <meta name="instagram:image:src" content={Logo} />

      <meta name="facebook:card" content="summary" />
      <meta name="facebook:creator" content={socialLinks.facebook} />
      <meta name="facebook:site" content={social.facebook} />
      <meta name="facebook:title" content={title} />
      <meta name="facebook:description" content={decription} />
      <meta name="facebook:image:src" content={Logo} />
      <link
        rel="stylesheet"
        href="https://use.fontawesome.com/releases/v5.5.0/css/all.css"
        integrity="sha384-B4dIYHKNBt8Bc12p+WXckhzcICo0wtJAoU8YZTY5qE0Id1GSseTk6S+L3BlXeVIU"
        crossOrigin="anonymous"
      />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700&display=swap"
        rel="stylesheet"
      />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
      />
      <script src="https://cdn.jsdelivr.net/npm/swiper@9/swiper-element-bundle.min.js"></script>
    </Helmet>
  );
};
