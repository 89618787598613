import React from 'react';

function Footer({ color }) {
  return (
    <footer className={`${color}`}>
      <ul className="copyright">
        <li>&copy; Naturlog Playa Guio. Reservados todos los derechos.</li>
        <li>
          Design:{' '}
          <a href="http://miigangls.com" target="_blank" rel="noreferrer">
            miigangls
          </a>
        </li>
      </ul>
    </footer>
  );
}

export default Footer;
