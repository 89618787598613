import React from 'react';
import { socialLinks } from '../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faYoutube, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { wrapper } from '../config/home';

const Contact = () => {
  const { id, title } = wrapper.contact;
  return (
    <section id={id} className="wrapper green ">
      <div className="container">
        <header>
          <h2 className="wow animate__animated animate__bounce animate__zoomIn">{`${title}`}</h2>
        </header>
        <div className="row contact">
          <article className="content text-left wow animate__animated animate__bounce animate__fadeInLeft">
            <p>
              <strong>
                <span>Horario de Atención:</span> Lunes a viernes
              </strong>{' '}
              de 8:00 am a 6:00 pm. <strong>Sábados</strong> de 9:00 am a 12:00 pm.
              <strong>Domingos:</strong> Solo atención directamente en recepción del Hotel o puede
              comunicarse al
              <strong>
                <span>
                  <span>315-547-8297</span>
                </span>
              </strong>
            </p>
          </article>
          <article className="content wow animate__animated animate__bounce animate__fadeInRight">
            <div className="social">
              <p>Síguenos en:</p>
              <ul className="icons">
                <li>
                  <a href={socialLinks.facebook} target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faFacebook} size={'sm'} />
                    <span className="label">Facebook</span>
                  </a>
                </li>
                <li>
                  <a href={socialLinks.instagram} target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faInstagram} size={'sm'} />
                    <span className="label">Instagram</span>
                  </a>
                </li>
                <li>
                  <a href={socialLinks.youtube} target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faYoutube} size={'sm'} />
                    <span className="label">Youtube</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="social ">
              <p>Contacto directo:</p>
              <ul className="icons">
                <li>
                  <a href={socialLinks.whatsapp} target="_blank" rel="noreferrer" className="">
                    <FontAwesomeIcon icon={faWhatsapp} size={'sm'} />
                    <span className="label">whatsapp</span>
                  </a>
                </li>
              </ul>
            </div>
          </article>
        </div>
      </div>
    </section>
  );
};

export default Contact;
