import React, { useEffect, useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { menu as MenuAnchorLink } from '../config/home';
import { Link } from 'gatsby';
import { socialLinks } from '../config/config';
import { NavHamburger } from './hamburger-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faYoutube, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const menu = [
  {
    title: 'inicio',
    href: '/',
  },
  {
    title: 'Nuestra Historia',
    href: '/about',
  },
  {
    title: 'Actividades',
    href: '/trails',
  },
  {
    title: 'Alojamiento',
    href: '/housing',
  },
  {
    title: 'Restaurante',
    href: '/restaurant',
  },
  {
    title: 'Galería',
    href: '/gallery',
  },
];

export const Header = ({ link }) => {
  const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 1440);
  const isMobile = width <= 768;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <header>
      <div className="container-fluid">
        {isMobile ? (
          <>
            <NavHamburger />
          </>
        ) : (
          <div className="navbar-header">
            <nav>
              <ul>
                {!link
                  ? MenuAnchorLink.map(({ title, href, link }) => {
                      return (
                        <li key={title}>
                          {link ? (
                            <Link key={title} to={`${href}`}>
                              {title}
                            </Link>
                          ) : (
                            <AnchorLink key={title} href={`#${href}`}>
                              {title}
                            </AnchorLink>
                          )}
                        </li>
                      );
                    })
                  : menu.map(({ title, href }) => {
                      return (
                        <li key={title}>
                          <Link key={title} to={`${href}`}>
                            {title}
                          </Link>
                        </li>
                      );
                    })}
              </ul>
            </nav>
            <div className="social">
              <ul className="icons">
                <li>
                  <a href={socialLinks.facebook} target="_blank" rel="noreferrer" className="icon">
                    <FontAwesomeIcon style={{ fontSize: 20 }} icon={faFacebook} size={'lg'} />
                    <span className="label">Facebook</span>
                  </a>
                </li>
                <li>
                  <a href={socialLinks.instagram} target="_blank" rel="noreferrer" className="icon">
                    <FontAwesomeIcon style={{ fontSize: 20 }} icon={faInstagram} size={'lg'} />
                    <span className="label">Instagram</span>
                  </a>
                </li>
                <li>
                  <a href={socialLinks.youtube} target="_blank" rel="noreferrer" className="icon">
                    <FontAwesomeIcon style={{ fontSize: 20 }} icon={faYoutube} size={'lg'} />
                    <span className="label">Youtube</span>
                  </a>
                </li>
                <li style={{  marginTop: -5}}>
                  <a href={socialLinks.whatsapp} target="_blank" rel="noreferrer" className="icon">
                    <FontAwesomeIcon style={{ fontSize: 32 }} icon={faWhatsapp} size={'2xl'} />
                    <span className="label">Whatsapp</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};
