import React from 'react';
import { Link } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { menu as MenuAnchorLink } from '../config/home';

const menu = [
  {
    title: 'inicio',
    href: '/',
  },
  {
    title: 'Actividades',
    href: '/trails',
  },
  {
    title: 'Alojamiento',
    href: '/housing',
  },
  {
    title: 'Restaurante',
    href: '/restaurant',
  },
  {
    title: 'Galería',
    href: '/gallery',
  },
];

export const NavHamburger = ({ link }) => {
  const [menuSmall, setMenuSmall] = React.useState(false);

  const handleClick = () => {
    if (!menuSmall) {
      document.body.classList.add('nav-panel-visible');
    } else {
      document.body.classList.remove('nav-panel-visible');
    }
    setMenuSmall(!menuSmall);
  };

  return (
    <>
      <div className="button-menu" onClick={handleClick}>
        <a className="toggle"></a>
      </div>
      <div className={`nav-panel`}>
        <nav>
          <ul>
            {!link
              ? MenuAnchorLink.map(({ title, href }) => {
                  return (
                    <li key={title}>
                      <AnchorLink key={title} href={`#${href}`}>
                        {title}
                      </AnchorLink>
                    </li>
                  );
                })
              : menu.map(({ title, href }) => {
                  return (
                    <li key={title}>
                      <Link key={title} to={`${href}`}>
                        {title}
                      </Link>
                    </li>
                  );
                })}
          </ul>
        </nav>
      </div>
    </>
  );
};
