import React from 'react';
import { socialLinks } from '../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

const IconWrapper = () => {
  return (
    <div className="icon_wrapper">
      <a
        href={socialLinks.facebook}
        aria-label="Facebook"
        target="_blank"
        rel="noreferrer"
        data-title="Facebook"
        className="icon"
      >
        <FontAwesomeIcon icon={faFacebook} size={'2xs'} />
      </a>
      <a
        href={socialLinks.instagram}
        aria-label="Instagram"
        target="_blank"
        rel="noreferrer"
        data-title="Instagram"
        className="icon"
      >
        <FontAwesomeIcon icon={faInstagram} size={'2xs'} />
      </a>
      <a
        href={socialLinks.youtube}
        aria-label="Youtube"
        target="_blank"
        rel="noreferrer"
        data-title="Youtube"
        className="icon"
      >
        <FontAwesomeIcon icon={faYoutube} size={'2xs'} />
      </a>
    </div>
  );
};

export default IconWrapper;
