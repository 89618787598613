import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const Logo = () => {
  const {
    file: {
      childImageSharp: {
        sizes: { srcWebp: publicURL },
      },
    },
  } = useStaticQuery(graphql`
    query QueryIconImg {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          sizes {
            srcWebp
          }
        }
      }
    }
  `);
  return (
    <img
      src={publicURL}
      className="logo animate__animated animate__bounce animate__fadeInDown"
      alt="logo"
    />
  );
};

export default Logo;
