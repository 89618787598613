module.exports = {
  menu: [
    {
      title: 'inicio',
      href: 'banner',
    },
    {
      title: 'Nuestra Historia',
      href: 'about',
    },
    {
      title: 'Servicios',
      href: 'services',
    },
    {
      title: 'Alojamiento',
      href: 'housing',
    },
    {
      title: 'Actividades',
      href: 'trails',
    },

    {
      title: 'Restaurante',
      href: 'restaurant',
    },
    {
      title: 'Galería',
      href: 'gallery',
      link: true,
    },
  ],
  wrappersTransparents: {
    items: [
      {
        title: 'Descubre las cosas que puedes encontrar ',
        id: 'wrappers-one',
        p: 'Martin pescador 5 especies diferentes Pava Amazónica o chenchena.',
        style: '',
      },
      {
        title: 'Descubre las cosas que puedes encontrar ',
        id: 'wrappers-two',
        p: 'Tucanes, Patos cormorán y pato aguja.',
        style: '',
      },
      {
        title: 'Descubre las cosas que puedes encontrar ',
        id: 'wrappers-three',
        p: 'Garza morena, Garza vaca, Colibrís, El jamuco el ave más grande de la zona.',
        style: '',
      },
      {
        title: 'Descubre las cosas que puedes encontrar ',
        id: 'wrappers-three',
        p: 'Garza morena, Garza vaca, Colibrís, El jamuco el ave más grande de la zonaGarza morena, Garza vaca, Colibrís',
        style: '',
      },
    ],
  },
  wrapper: {
    banner: {
      title: 'ECOTURISMO Y ESPERANZA',
      id: 'banner',
      description:
        'Somos Familias Guardabosques que erradicaron cultivos ilícitos en esta región para dedicar nuestro entorno y conocimientos al Ecoturismo, contemplación de especies y disfrute de nuestra cultura culinaria y tradiciones.',
      style: '',
    },
    about: {
      title: 'Nuestra Historia',
      id: 'about',
      style: '',
      items: [
        {
          name: 'Horario: 7:00 am a 9:00 pm',
          icon: 'cabin.jpg',
          float: 'left',
          description:
            'Somos Familias Guardabosques que erradicaron cultivos ilícitos en esta región para dedicar nuestro entorno y conocimientos al Ecoturismo,  está ubicada en la vereda Playa Güío del municipio de San José del Guaviare, Departamento del Guaviare, en el noroccidente de la Amazonia colombiana. Tiene una ubicación privilegiada para el ecoturismo por las particularidades de los ecosistemas que convergen en la zona, ya que está localizada en la confluencia del río Guaviare, la Laguna Negra y el Caño Negro.',
        },
      ],
    },
    services: {
      title: '¿Qué deseas hacer?',
      id: 'services',
      p: '',
      style: '',
      items: [
        {
          name: 'senderismo vistas guiadas',
          icon: 'IconExcursionismo',
          float: 'right',
          href: 'trails',
          description:
            'Visitas guiadas a los principales atractivos turìsticos naturales de nuestro despartamento formaciones rocosa muy antuguios, cascadas, tuneles naturales, arte rupestre de mas 12.000 años de antiguedad',
        },
        {
          name: 'ALOJAMIENTO GRUPAL',
          icon: 'IconCabin',
          float: 'left',
          href: 'housing',
          description:
            'Alojamiento en cabanas ecologicas  contruidas en madera remodelada de bosque amazonico con una gran biodiversidad, un lugar perfecto para tener un contacto directo con la naturaleza',
        },
        {
          name: 'Sendero acuatico',
          icon: 'IconCanoe',
          float: 'right',
          href: 'trails',
          description:
            'Desde el alojamiento podemo aser Kayak o dar un paseo en bote y disfrutar del sendero observacion de aves, primates, flora y fauna, ademas nadas en la laguna.',
        },
      ],
    },
    housing: {
      title: 'ALOJAMIENTOS',
      id: 'housing',
      p: 'Alojamientos rurales en Cabañas Ecológicas construidas en maderas nativas,',
      style: '',
      items: [
        {
          name: 'CABAÑAS',
          icon: 'cabanasMin',
          float: 'right',
          description:
            'Contamos con (3) Cabañas , Cada una con (1) una Cama Doble y (1) una Sencilla, Mosquiteros, Energía solar, Baño con Ducha Privada y Balcón con Amaca. Capacidad máxima (3) Tres Personas por cabaña',
        },
        {
          name: 'CAMPAMENTO',
          icon: 'campamento',
          float: 'left',
          description:
            'Acomodaciones con (3) Tres Baños y (4) Cuatro Duchas , Mosquiteros, Energía solar , ideal para grupos grandes de Viajeros o Estudiantes. Acomodación múltiple en camarotes. Próximamente con divisiones de espacios Capacidad máxima (20) Veinte Personas en el Campamento',
        },
      ],
    },
    trails: {
      title: 'SENDEROS',
      id: 'trails',
      p: 'SENDEROS ruraleS CON LA MEJOR EXPERIENCIAS',
      style: '',
      items: [
        {
          name: 'fluvial Caño Negro a Laguna Negra.',
          icon: 'fluvial',
          float: 'right',
          description:
            'Este sendero se puede hacer en canoa con motor o en kayak un sendero rodeado de naturaleza propia de la Amazonia y una gran biodiversidad que convierte este sendero ideal para la admiración de la naturaleza ideal para la observación de aves y primates.',
        },
        {
          name: 'Río Guaviare',
          icon: 'guaviare',
          float: 'left',
          description:
            'Caminata por medio de bosque a Amazonico inundable aprox. 1 kilometro de largo hasta llegar al Rio Guaviare se pueden observar primates y aves de playa a la orilla del Rio Guaviare ademas de pasar por cultivos de cacao que en su momento remplazaron la coca en esta zona',
        },
      ],
    },
    restaurant: {
      title: 'restaurante',
      id: 'restaurant',
      p: 'Contamos con una gastronomia unica de la region',
      style: '',
      items: [
        {
          name: 'Horario: 7:00 am a 9:00 pm',
          icon: 'cabin.jpg',
          float: 'left',
          description:
            'Ofrecemos servicio de alimentación (desayunos, almuerzos y cenas) con productos propios de la región y preparados por personas de la comunidad en fogones de leña. Predomina el pescado, la carne de gallina y productos agrícolas como la yuca, el plátano y el maíz, todos producidos en Playa Güío por la comunidad.',
        },
      ],
    },
    contact: {
      title: 'contacto',
      id: 'contacto',
      p: 'Hablemos de su proyecto ',
      items: [
        {
          name: 'Horario: 7:00 am a 9:00 pm',
          icon: 'cabin.jpg',
          float: 'left',
          description:
            'Nuestros platos principalmente son platos campesinos preparados en fogón de leña utilizando productos del campo, Frutos de nuestra region, donde sobresale…',
        },
      ],
    },
    videos: {
      id: 'videos',
      items: [
        {
          url: 'https://www.youtube.com/embed/MPs9fuKdJMs',
        },
        {
          url: 'https://www.youtube.com/embed/Ko2RN_4BLxs',
        },
        {
          url: 'https://www.youtube.com/embed/hhwb8EHAX-o',
        },
      ],
    },
  },
};
