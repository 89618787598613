module.exports = {
  defaultTitle: 'Naturlog Playa Guio',
  logo: 'logo-90f29cab542e2499f33ae0fc3b2a7add.svg',
  author: 'miigangls',
  url: 'https://naturlogplayaguio.com',
  legalName: 'naturlogplayaguio',
  defaultDescription:
    'Fuimos familia guardabosques que decimos voluntariamente dejar la coca por el ecoturismo onde los locales somos los anfitriones',
  socialLinks: {
    facebook: 'http://www.facebook.com/100068251931119',
    instagram: 'https://instagram.com/naturlogplayaguio',
    youtube: 'https://www.youtube.com/watch?v=BmfVSAW_vuI',
    whatsapp:
      'https://api.whatsapp.com/send?phone=%2B573155478297&data=AWDS24eOhtAjSW5ZPYGxTuyVlyWgfnnHk_vSVj3ssv9Kh50G9pUWNxefw45Y0EGNQCPp_GAiF5UCn9F9HO1L985AYpnF6iTu6SYtZfZ3153XjHx3VhWXMQKnsgwseEOR4HxM1uvzEm8n6HZh0GREzUaQo-4zID_tcBds2I3LUY3LQTJHDzcE_sTCbmalnaPZeSO9sGar9kJkRH_wN21y1DrTui7uyYCpGAZdOR0Mci7GC81EKiuE6mZImEN-dYmadFDcXxoI4-sS7_wfd3aV9Q&source=FB_Page&app=facebook&entry_point=page_cta&fbclid=IwAR08wExzyaDICj8hrqHVS6th_vxSaI1PUYjtf-3rasvPY2KvzjA6yBJG2fA',
  },
  social: {
    facebook: 'naturlogplayaguio',
    twitter: '@naturlogplayaguio',
  },
  banner: {
    trails: {
      title: 'Vive Experiencias Increíbles e Inolvidables',
      description:
        'Somos Familias Guardabosques que erradicaron cultivos ilícitos en esta región para dedicar nuestro entorno y conocimientos al Ecoturismo, contemplación de especies y disfrute de nuestra cultura culinaria y tradiciones.',
    },
    housing: {
      title: 'Vive la Naturaleza y la Aventuras',
      description:
        'Ecológicas construidas en madera rodeadas de bosque amazónico con una gran biodiversidad, un lugar perfecto para tener un contacto directo con la naturaleza',
    },
    restaurant: {
      title: 'Restaurante',
      description: 'Con todo el Sabor y Encanto de Nuestra Región.',
    },
  },
};
