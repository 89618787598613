import React from 'react';
import { wrapper } from '../../../config/home';
import excursionismo from '../../../assets/images/svg/excursionismo.svg';
import canoe from '../../../assets/images/svg/canoe.svg';
import cabin from '../../../assets/images/svg/cabin.svg';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const ObjectIcons = {
  IconExcursionismo: excursionismo,
  IconCanoe: canoe,
  IconCabin: cabin,
};

export const Services = () => {
  const { id, title, items } = wrapper.services;
  return (
    <section id={id} className="wrapper green">
      <div className="container">
        <header>
          <h2 className="wow animate__animated animate__bounce animate__zoomIn">{`${title}`}</h2>
        </header>
        {items.map((row, i) => (
          <div className={`row`} key={`${row}-${i}`}>
            <article className={`col-12 col-12-small `}>
              <AnchorLink key={row.href} href={`#${row.href}`}>
                <div className={`services ${row.float}`}>
                  {row.float === 'left' && (
                    <img
                      className="svg wow animate__animated animate__bounce animate__fadeInLeft"
                      src={ObjectIcons[row.icon]}
                      alt={`icon-${row.icon}`}
                    />
                  )}
                  <div className="content fadeInUp animated wow animate__animated animate__bounce animate__fadeInUp">
                    <header>
                      <h2 className={`aling-${row.float} `}>{row.name}</h2>
                    </header>
                    <p>{row.description}</p>
                  </div>
                  {row.float === 'right' && (
                    <img
                      className="svg wow animate__animated animate__bounce animate__fadeInRight"
                      src={ObjectIcons[row.icon]}
                      alt={`icon-${row.icon}`}
                    />
                  )}
                </div>
              </AnchorLink>
            </article>
          </div>
        ))}
      </div>
    </section>
  );
};
